header,
footer {
  height: 100px;
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #212121;
  color: #ffffff;
}
.uploaded__img {
  height: 300px;
  width: 100%;
  border-radius: 25px;
}
.card {
  border: none;
}
