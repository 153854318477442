@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700;800;900&display=swap');
body {
	font-family: 'Nunito', sans-serif !important;
}

@for $fw from 1 through 9 {
	.fw--#{$fw * 100} {
		font-weight: $fw * 100;
	}
}
.space {
	width: 100%;
	border-radius: 5px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	font-weight: 700;
	&--1 {
		height: 150px;
	}
	&--2 {
		height: 80px;
	}
	&--3 {
		height: 100%;
	}
}
.dropzone {
	// height: 300px;
	border: 2px solid rgba(0, 0, 0, 0.2);
	font-weight: 600;
	font-size: 24px;
	border-radius: 5px;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.125);
	position: relative;
	&__invalid {
		border-color: red;
	}
	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		backdrop-filter: blur(15px);
		font-weight: 700;
		font-size: 32px;
		text-shadow: 0 0 10px #fff;
		z-index: 999;
		&--error {
			background-color: rgb(255, 0, 0, 0.125);
			color: red;
		}
		&--loading {
			background-color: rgba(0, 128, 0, 0.125);
			color: white;
		}
	}
}
.image-container {
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 5px;
}
.download-card {
	width: 100%;
	aspect-ratio: 1/1;
	background-position: center;
	background-size: cover;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	padding: 10px;
	box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 30%);
	&__filename {
		font-size: 12px;
		@media (max-width: 991.98px) {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
